import React, {Component} from 'react';
import ArrowRightLong from "@material-ui/icons/ArrowRightAlt"

class BreadCrumbs extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="bread-crumbs">
                <div className="crumbs-inner">
                    <div className="crumbs-side">
                        {
                            this.props.breadCrumbsList && this.props.breadCrumbsList.length > 0 && this.props.breadCrumbsList.map((breadCrumb) => {
                                    return (
                                        <div>
                                            <a href={breadCrumb.link} className="crumbs-text">
                                                {breadCrumb.title}
                                            </a>
                                            <ArrowRightLong/>
                                        </div>
                                    )
                                }
                            )
                        }
                        {
                            this.props.currentPage && (
                                <p className="crumbs-text-black">{this.props.currentPage}</p>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default BreadCrumbs;