import {Link} from "gatsby"
import React from "react"
import "./custom-card.scss"

const populateData = (data, type) => {
    if (data && data.length > 0) {
        const courseTye = type === "courses";
        const groupType = type === "groups";
        const providerType = type === "providers";

        data = data.map(item => {
            return {
                imageUrl: courseTye ? item.courseImage?.file?.url
                    : (providerType ? item.educationImage?.file?.url
                        : item.groupImage?.file?.url),
                title: courseTye ? item.courseName : (providerType
                    ? item.fullName : item.groupName),
                subtitle: courseTye ? item.description : (providerType ? ""
                    : item.groupDescription),
                noOfChapters: courseTye ? item.noOfChapters : null,
                groupOrganizerName: groupType ? item.groupOrganizerName : null,
                providerImages: providerType ? item.providerImages : null,
                slug: item.slug,
                memberCount: item.memberCount ? item.memberCount : null,
            }
        });
        return data
    }

};

const getPagePath = (type, slug) => {
    switch (type) {
        case "courses": {
            return `/how-to-guides/mental-health-and-substance-use/${slug}/`
        }
        case "groups": {
            return `/support-groups/online-support-groups/${slug}/`
        }
        default: {
            return `/${slug}/`
        }
    }
};

const navigateToDetailScreen = (type, slug) => {
    window.location.assign(getPagePath(type, slug));
};

const CustomCard = (props) => {
    const newData = populateData(props.newdata, props.type);
    return (
        <div className="card-wrap">
            <div className="card-list">
                {
                    newData && newData.slice(0, 3).map((data, index) => {
                        return (
                            <div key={index} className="single-card">
                                <img className="card-img"
                                     src={"https:" + data.imageUrl}
                                     onClick={() => navigateToDetailScreen(
                                         props.type, data.slug)}
                                     alt="Card Visual"/>
                                <div className="card-content-section">
                                    {data.title && (
                                        <h3 onClick={() => navigateToDetailScreen(
                                            props.type, data.slug)}
                                            className="card-title">{data.title}</h3>
                                    )}

                                    {data.groupName && (
                                        <h3 onClick={() => navigateToDetailScreen(
                                            props.type, data.slug)}
                                            className="card-title">{data.groupName}</h3>
                                    )}
                                    <div className="card-info-wrap">
                                        {data.noOfChapters && (
                                            <p className="card-info">{data.noOfChapters} Chapters </p>
                                        )}
                                        {data.groupOrganizerName && (
                                            <p className="card-info">{data.groupOrganizerName} </p>
                                        )}
                                    </div>
                                    <div className="card-info-wrap">
                                        {data.memberCount && (
                                            <p className="card-info">{data.memberCount} members </p>
                                        )}
                                    </div>
                                    <p className="card-desc">{data.subtitle}</p>
                                    <Link className="outline-btn"
                                          to={getPagePath(props.type,
                                              data.slug)}>Learn
                                        More</Link>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>

    )

};

export default CustomCard
