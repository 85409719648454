import React, {Component} from "react"
import Layout from "../components/layout"
import TextField from "@material-ui/core/TextField/TextField";
import CustomCard from "../components/common-design/custom-card"
import WaveSvg from "../assets/images/Wave.svg";
import Button from "@material-ui/core/Button";
import ArrowNavigationIcon from '@material-ui/icons/NearMe'
import ArrowForwardThinIcon from '@material-ui/icons/ArrowForward'
import {graphql} from 'gatsby';
import {isValidContentfulImage, isValidPhone, isValidSectionField} from "../common/utils/CommonUtils";
import SEO from "../components/seo"
import {SegmentAnalytics} from "../common/utils/SegmentAnalytics";
import {SegmentEvents} from "../common/utils/SegmentSpec";
import BreadCrumbs from "../components/bread-crumbs";
import "./courses.scss";
import {BranchUtils} from "../common/utils/BranchUtils";

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    },
    {
        title: "Get Started",
        link: "/get-started",
    },

]
class HowToGuides extends Component {

    constructor(props) {

        super(props);
        this.state = {
            error: false,
            contentLoaded: false,
            SearchedArticles: [],
            questions: [],
            searchLibraryValue: "",
            Searched: false,
            loader: {
                options: {
                    position: "absolute",
                },
            },
            phone: "",
            success: false,
            entities: [],
        };
        this.pageData = this.getFirstContentfulNode(this.props,
            "allContentfulCoursesLandingPage")
    }

    getFirstContentfulNode = (props, key) => {
        return props.data[key].nodes[0]
    };

    sendPhone(event, phoneNumber) {
        event.preventDefault();
        const phone = isValidPhone(phoneNumber);
        if (phone) {

            SegmentAnalytics.track(SegmentEvents.LEARN_WITH_CONFIDANT_COURSES, {
                phone,
                'formLocation': 'course-landing-page',
                appRequested: true,
            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'formLocation': 'course-landing-page',
                'event': 'GET_THE_APP',
                'phone': phone
            });

            BranchUtils.desktopSendSMS(phone, 'course-landing-page','Course Sign Up', 'course-landing-page');

            this.setState({success: true});

        } else {
            console.log("this.state.error", this.state.error);
            this.setState({
                error: true,
                success: false,
                phone: ''
            });
        }
    };

    render() {

        const {topSection, shortSections, courses} = this.pageData;
        return (
            <Layout location={this.props.location}>
                <SEO title="Self Directed Courses"/>
                <BreadCrumbs currentPage={"How To Guides"} breadCrumbsList={breadCrumbsList}/>
                <div className="new-common-top-header">
                    <div className="new-common-top-inner">
                        {
                            isValidContentfulImage(topSection.imageOrVideo) && (
                                <img
                                    className="new-common-top-hero-img provider-details"
                                    src={require(
                                        '../assets/images/courseMobile.png')}
                                    alt="iPhone Visual"/>
                            )
                        }
                        <div className="new-common-top-pattern-content">
                            {
                                isValidSectionField(topSection, "title") &&
                                <h2 className="new-common-top-main-head">{topSection.title}</h2>
                            }
                            {
                                isValidSectionField(topSection, "description")
                                &&
                                <p className="new-common-top-sub-head"
                                   dangerouslySetInnerHTML={{__html: topSection.description.description}}></p>
                            }
                            <form method="post"
                                  onSubmit={(event) => this.sendPhone(event,
                                      this.state.phone)}>
                                <div className="get-app-input-wrap">
                                    <div className="input-box">
                                        <TextField
                                            id="phone-input"
                                            className="number-field"
                                            placeholder="Enter your Phone Number"
                                            value={this.state.phone}
                                            margin="normal"
                                            onChange={event => {
                                                const {value} = event.target;
                                                this.setState({
                                                    phone: value,
                                                    error: false,
                                                    success: false
                                                });
                                            }}
                                        />
                                    </div>
                                    <Button
                                        className="blue-arrow-btn"
                                        color="primary"
                                        type="submit"
                                        //onClick={() => {this.sendEmail(this.state.email)}}
                                    >
                                        Get the App <ArrowNavigationIcon/>
                                    </Button>
                                </div>
                            </form>
                            {this.state.error ? <p className="error-msg">Please
                                Enter Valid phone Number</p> : null}
                            {this.state.success ? <p
                                className="success-msg">Got it! Sending you a text with the link</p> : null}
                        </div>
                    </div>
                </div>

                <div className="common-wave-wrapper"
                     style={{backgroundImage: `url(${WaveSvg})`}}>
                    <img className="confetti-bg"
                         src={require('../assets/images/confeti-pattern.svg')}
                         alt="Pattern"/>
                    <div className="wave-bg">
                        <div className="common-wave-inner">
                            <h2 className="common-wave-heading">Expert Curated
                                "How-to" Guides and Interactive Courses</h2>
                            <p className="common-wave-des">Confidant Courses are
                                a DIY approach to learning about mental
                                health and addiction, or working toward your
                                goals. Some courses are recommended by our
                                clinical team based on your care plan, or you
                                can opt-in on your own.
                                It's a great way to increase your knowledge and
                                enhance your experience.</p>
                            {courses && courses.length > 0 && (
                                <CustomCard newdata={courses} type="courses"/>
                            )}
                            <Button
                                href="/how-to-guides/mental-health-and-substance-use/"
                                className="new-blue-link"
                                color="primary">
                                See all of the Confidant Courses and
                                Guides <ArrowForwardThinIcon/>
                            </Button>
                        </div>

                    </div>

                </div>

                <div className="common-feature-wrapper">
                    <div className="common-feature-inner">
                        <h2 className="common-feature-head">Why choose to use
                            Confidant Courses?</h2>
                        <p className="common-feature-des">
                            It’s difficult to access trustworthy information on
                            mental health and addiction.
                            This leads to people making decisions out of
                            desperation or misunderstanding.
                            We’ve designed our courses to specifically to
                            counteract this. With Confidant Courses,
                            it's easy to access credible and topically relevant
                            content that’s tailored to you.
                        </p>

                        {
                            shortSections && shortSections.map(
                                (section, index) => (
                                    <div key={"key-" + section.heading}
                                         className="common-feature-single-feature">
                                        {
                                            (index === 0 || index % 2 === 0)
                                            && (
                                                <div
                                                    className="common-feature-img-side">
                                                    {isValidContentfulImage(
                                                        section.image) && (
                                                        <img
                                                            className="common-feature-image"
                                                            src={section.image.file.url}
                                                            alt="Visual Frame"/>
                                                    )
                                                    }
                                                </div>
                                            )
                                        }
                                        <div
                                            className="common-feature-text-side">
                                            {
                                                isValidSectionField(section,
                                                    "heading") &&
                                                <h2 className="common-feature-heading">
                                                    {section.heading}
                                                </h2>
                                            }
                                            {
                                                isValidSectionField(section,
                                                    "description") &&
                                                <p className="common-feature-para"
                                                   dangerouslySetInnerHTML={{__html: section.description.description}}></p>
                                            }
                                            <Button href={`/${section.link}/`}
                                                    className="new-blue-link"
                                                    color="primary">
                                                {section.linkText}
                                            </Button>
                                        </div>
                                        {
                                            index % 2 !== 0 && (
                                                <div
                                                    className="common-feature-img-side"
                                                    style={{textAlign: "right"}}>
                                                    {isValidContentfulImage(
                                                        section.image) && (
                                                        <img
                                                            className="common-feature-image"
                                                            src={section.image.file.url}
                                                            alt="Visual Frame"/>
                                                    )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                ))
                        }

                    </div>
                </div>

            </Layout>
        )
    }

}

export default HowToGuides

export const query = graphql`query getCoursesLandingData {
  allContentfulCoursesLandingPage {
    nodes {
      id
      topSection {
         title
        id
     ... on ContentfulPageTopSection {
        description {
          description
        }
        imageOrVideo {
          file {
            url
          }
        }
        }
      }
       courses {
        courseName
        description
        slug
        courseImage {
          file {
            url
          }
        }
      }
      shortSections {
     ... on ContentfulShortSection{
        description {
          description
        }
        image {
          file {
            url
          }
        }
        heading
      }
    }
  }
  }
}

`;

